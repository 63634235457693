$.fn.datetimepicker = require('@eonasdan/tempus-dominus');


$(document).ready(function () {
    $('#change').click(function () {
        $(this).find(".container-change .change-img .glyphicon").toggleClass("glyphicon-chevron-right glyphicon-chevron-down");
        $(this).toggleClass("background-grey background-dark-grey");
    });

    $('.form-group label').each(function (e) {
        if (!$(this).hasClass("control-label")) {
            $(this).addClass("control-label");
        }
    });

    setTimeout(function() {
        $('#content input[type="text"], #content input[type="date"], .form-group select, .form-group textarea, .form-group input[type="date"], .form-group input[type="text"], .form-group input[type="number"], .ntic-section-form input[type="text"], .ntic-section-form textarea, .ntic-section-form select, .ntic-section-form input[type="number"]').each(function (e) {
            if (!$(this).hasClass("form-control")) {
                $(this).addClass("form-control");
            }
        });
    }, 1000);

    $('button[type="submit"]').each(function (e) {
        if (!$(this).hasClass("btn")) {
            $(this).addClass("btn");
        }
    });


    $('.panel-body input[type="radio"], .panel-body input[type="checkbox"], #content input[type="radio"], #content input[type="checkbox"]').each(function (e) {
        $(this).wrapAll('<div class="radio custom-section-fi">');
    });

    $('.custom-section-fi').each(function (e) {
        $(this).next().appendTo($(this));
    });

    // $('.js-datepicker').datepicker({
    //     format: 'yyyy-mm-dd'
    // });

    var menuBurger = document.getElementById('menu-burger');
    var burgerOpener = document.getElementById('burger-opener');
    $('#burger-opener').click(function () {
        openBurger();
    });
    function openBurger() {
        if (menuBurger.style.display == 'none') {
        menuBurger.style.display = 'flex';
        burgerOpener.innerHTML = '<i class="fas fa-times"></i>';
        } else {
        menuBurger.style.display = 'none';
        burgerOpener.innerHTML = '<i class="fas fa-bars"></i>';
        }
    }
});
